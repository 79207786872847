







































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component({
  components: {},
})
export default class About extends Vue {
  private name = "";
  private cardNum = "";
  private data = {};

  async search() {
    if (!this.name || !this.cardNum) {
      return alert("请完整输入您的名字或者身份证号");
    }
    console.log(this.name);
    const { data } = await axios.post(
      "https://info.jingziyou.com.cn/search/score",
      {
        name: this.name,
        num: this.cardNum,
      },
      {
        validateStatus: () => true,
      }
    );
    if (data.code !== 0) {
      return alert(data.message);
    }
    this.data = data.data;
  }
}
